import React from "react"
import {PageProps} from "gatsby"

import {Page} from "../../types"

import BannerGroup from "../components/banner-group"
import Layout from "../components/layout"
import Sidebar from "../components/sidebar"

const ThemesLanding = (page: PageProps): JSX.Element => {
  const data = page.pageContext as Page

  return (
    <Layout title={data.title} currentPage={data}>
      <BannerGroup banners={data.header} size="large" />
      <Sidebar
        currentPage={data}
        landingPageType="themes-landing-page"
        rootTitle="Actuele thema's"
        alphabetised
      />
    </Layout>
  )
}

export default ThemesLanding
